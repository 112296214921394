import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import Privacy from 'components/Privacy/Privacy';

const PrivacyPage = ({ data }) => (
  <Layout page="privacy">
    <Privacy
      pageData={data.allContentfulPage.edges[0].node}
    />
  </Layout>
);

export default PrivacyPage;
export const query = graphql`
  query {
    allContentfulPage(
      filter: {
        slug: {
          eq: "privacy-policy"
        }
      }
    ) {
      edges {
        node {
          slug
          title
          description { json }
        }
      }
    }
  }
`;
