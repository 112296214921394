import React from 'react';

import Section from 'components/UI/Section/Section';
import Slider from 'components/UI/Slider/Slider';
import Title from 'components/UI/Title/Title';
import Text from 'components/UI/Text/Text';
import Promo from 'components/UI/Promo/Promo';

import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';


const Privacy = ({ pageData }) => {
  const {
    title,
    subtitle,
    description,
    blockInfo,
    blockFeature,
  } = pageData;

  return (
    <Section>
      <Title>
          <Text as="h2" line stack>{title}</Text>
          {description && <ContentfulRichText
            richTextAST={description.json}
          />}
      </Title>


    </Section>
  );
};

export default Privacy;
